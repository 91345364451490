<template>
  <b-container fluid>
    <b-row>
      <b-col>
        <b-card-group deck class="padding">
          <b-card
            v-if="
              this.show_output_person_location &&
                this.output_person_location.labels.length > 0
            "
            style="box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;"
          >
            <h5>
              person_location
            </h5>

            <pieChart
              v-bind:labels="this.output_person_location.labels"
              v-bind:resData="this.output_person_location.data"
              v-bind:background="this.output_person_location.background"
              v-bind:border="this.output_person_location.border"
            ></pieChart>
          </b-card>

          <b-card
            v-if="
              this.show_output_person_extractedGender &&
                this.output_person_extractedGender.labels.length > 0
            "
            style="box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;"
          >
            <h5>
              person_extractedGender
            </h5>

            <pieChart
              v-bind:labels="this.output_person_extractedGender.labels"
              v-bind:resData="this.output_person_extractedGender.data"
              v-bind:background="this.output_person_extractedGender.background"
              v-bind:border="this.output_person_extractedGender.border"
            ></pieChart>
          </b-card>

          <b-card
            v-if="
              this.show_output_person_extractedRole &&
                this.output_person_extractedRole.labels.length > 0
            "
            style="box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;"
          >
            <h5>
              person_extractedRole
            </h5>

            <pieChart
              v-bind:labels="this.output_person_extractedRole.labels"
              v-bind:resData="this.output_person_extractedRole.data"
              v-bind:background="this.output_person_extractedRole.background"
              v-bind:border="this.output_person_extractedRole.border"
            ></pieChart>
          </b-card>
        </b-card-group>

        <b-card-group deck class="padding">
          <b-card
            v-if="
              this.show_output_person_extractedSeniority &&
                this.output_person_extractedSeniority.labels.length > 0
            "
            style="box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;"
          >
            <h5>
              person_extractedSeniority
            </h5>

            <pieChart
              v-bind:labels="this.output_person_extractedSeniority.labels"
              v-bind:resData="this.output_person_extractedSeniority.data"
              v-bind:background="
                this.output_person_extractedSeniority.background
              "
              v-bind:border="this.output_person_extractedSeniority.border"
            ></pieChart>
          </b-card>

          <b-card
            v-if="
              this.show_output_company_french_codeNaf &&
                this.output_company_french_codeNaf.labels.length &&
                this.output_company_french_codeNaf.labels.length > 0
            "
            style="box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;"
          >
            <h5>
              company_french_codeNaf
            </h5>

            <pieChart
              v-bind:labels="this.output_company_french_codeNaf.labels"
              v-bind:resData="this.output_company_french_codeNaf.data"
              v-bind:background="this.output_company_french_codeNaf.background"
              v-bind:border="this.output_company_french_codeNaf.border"
            ></pieChart>
          </b-card>

          <b-card
            v-if="
              this.show_output_company_french_libelleCodeNaf &&
                this.output_company_french_libelleCodeNaf.labels.length > 0
            "
            style="box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;"
          >
            <h5>
              company_french_libelleCodeNaf
            </h5>

            <pieChart
              v-bind:labels="this.output_company_french_libelleCodeNaf.labels"
              v-bind:resData="this.output_company_french_libelleCodeNaf.data"
              v-bind:background="
                this.output_company_french_libelleCodeNaf.background
              "
              v-bind:border="this.output_company_french_libelleCodeNaf.border"
            ></pieChart>
          </b-card>
        </b-card-group>

        <b-card-group deck class="padding">
          <b-card
            v-if="
              this.show_output_company_french_effectif &&
                this.output_company_french_effectif.labels.length &&
                this.output_company_french_effectif.labels.length > 0
            "
            style="box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;"
          >
            <h5>output_company_french_effectif</h5>

            <pieChart
              v-bind:labels="this.output_company_french_effectif.labels"
              v-bind:resData="this.output_company_french_effectif.data"
              v-bind:background="this.output_company_french_effectif.background"
              v-bind:border="this.output_company_french_effectif.border"
            ></pieChart>
          </b-card>

          <b-card
            v-if="
              this.show_output_company_full_cards_overview_fields_numEmployee &&
                this.output_company_full_cards_overview_fields_numEmployee
                  .labels.length > 0
            "
            style="box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;"
          >
            <h5>
              company_full_cards_overview_fields_numEmployee
            </h5>

            <pieChart
              v-bind:labels="
                this.output_company_full_cards_overview_fields_numEmployee
                  .labels
              "
              v-bind:resData="
                this.output_company_full_cards_overview_fields_numEmployee.data
              "
              v-bind:background="
                this.output_company_full_cards_overview_fields_numEmployee
                  .background
              "
              v-bind:border="
                this.output_company_full_cards_overview_fields_numEmployee
                  .border
              "
            ></pieChart>
          </b-card>

          <b-card
            v-if="
              this.show_output_company_premium_industries &&
                this.output_company_premium_industries.labels.length > 0
            "
            style="box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;"
          >
            <h5>
              company_premium_industries
            </h5>

            <pieChart
              v-bind:labels="this.output_company_premium_industries.labels"
              v-bind:resData="this.output_company_premium_industries.data"
              v-bind:background="
                this.output_company_premium_industries.background
              "
              v-bind:border="this.output_company_premium_industries.border"
            ></pieChart>
          </b-card>
        </b-card-group>

        <b-card-group deck class="padding">
          <b-card
            v-if="
              this.show_output_company_premium_companySize &&
                this.output_company_premium_companySize.labels.length > 0
            "
            style="box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;"
          >
            <h5>
              company_premium_companySize
            </h5>

            <pieChart
              v-bind:labels="this.output_company_premium_companySize.labels"
              v-bind:resData="this.output_company_premium_companySize.data"
              v-bind:background="
                this.output_company_premium_companySize.background
              "
              v-bind:border="this.output_company_premium_companySize.border"
            ></pieChart>
          </b-card>

          <b-card
            v-if="
              this.show_output_company_premium_type &&
                this.output_company_premium_type.labels.length > 0
            "
            style="box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;"
          >
            <h5>
              show_output_company_premium_type
            </h5>

            <pieChart
              v-bind:labels="this.output_company_premium_type.labels"
              v-bind:resData="this.output_company_premium_type.data"
              v-bind:background="this.output_company_premium_type.background"
              v-bind:border="this.output_company_premium_type.border"
            ></pieChart>
          </b-card>
        </b-card-group>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { GET_CHART } from "../../../store/constant/chart.type";
import { loadingService } from "../../common/services/loading.service";
export default {
  data() {
    return {
      fullPage: false,
      loader: "bars",
      URL: "",
      show_output_person_location: false,
      show_output_person_extractedGender: false,
      show_output_person_extractedRole: false,
      show_output_person_extractedSeniority: false,
      show_output_company_french_codeNaf: false,
      show_output_company_french_libelleCodeNaf: false,
      show_output_company_french_effectif: false,
      show_output_company_full_cards_overview_fields_numEmployee: false,
      show_output_company_premium_industries: false,
      show_output_company_premium_companySize: false,
      show_output_company_premium_type: false,
      height: 300,
      output_person_location: {
        labels: [],
        data: [],
        background: [],
        border: [],
      },
      output_person_extractedGender: {
        labels: [],
        data: [],
        background: [],
        border: [],
      },
      output_person_extractedRole: {
        labels: [],
        data: [],
        background: [],
        border: [],
      },
      output_person_extractedSeniority: {
        labels: [],
        data: [],
        background: [],
        border: [],
      },
      output_company_french_codeNaf: {
        labels: [],
        data: [],
        background: [],
        border: [],
      },
      output_company_french_libelleCodeNaf: {
        labels: [],
        data: [],
        background: [],
        border: [],
      },
      output_company_french_effectif: {
        labels: [],
        data: [],
        background: [],
        border: [],
      },
      output_company_full_cards_overview_fields_numEmployee: {
        labels: [],
        data: [],
        background: [],
        border: [],
      },
      output_company_premium_industries: {
        labels: [],
        data: [],
        background: [],
        border: [],
      },
      output_company_premium_companySize: {
        labels: [],
        data: [],
        background: [],
        border: [],
      },
      output_company_premium_type: {
        labels: [],
        data: [],
        background: [],
        border: [],
      },
    };
  },
  mounted() {
    // let externalScript = document.createElement("script");
    // externalScript.setAttribute("src", "https://cdn.firstpromoter.com/fpr.js");
    // // externalScript.setAttribute("cid", "9iau5tg8");
    // document.head.appendChild(externalScript);
  },
  components: {
    pieChart: () => import("../chartBlock/pieChart.vue"),
  },
  methods: {
    showChart(key) {
      switch (key) {
        case "/output_person_location":
          this.show_output_person_location = true;
          break;
        case "/output_person_extractedGender":
          this.show_output_person_extractedGender = true;
          break;
        case "/output_person_extractedRole":
          this.show_output_person_extractedRole = true;
          break;
        case "/output_person_extractedSeniority":
          this.show_output_person_extractedSeniority = true;
          break;
        case "/output_company_french_codeNaf":
          this.show_output_company_french_codeNaf = true;
          break;
        case "/output_company_french_libelleCodeNaf":
          this.show_output_company_french_libelleCodeNaf = true;
          break;
        case "/output_company_french_effectif":
          this.show_output_company_french_effectif = true;
          break;
        case "/output_company_full_cards_overview_fields_numEmployee":
          this.show_output_company_full_cards_overview_fields_numEmployee = true;
          break;
        case "/output_company_premium_industries":
          this.show_output_company_premium_industries = true;
          break;
        case "/output_company_premium_companySize":
          this.show_output_company_premium_companySize = true;
          break;
        case "/output_company_premium_type":
          this.show_output_company_premium_type = true;
          break;

        default:
          break;
      }
    },
    async callChartApI(callFor, setData, URL) {
      loadingService.setloader(true);
      await this.$store
        .dispatch(GET_CHART, `${URL}${callFor}`)
        .then((result) => {
          loadingService.setloader(false);
          this.showChart(callFor);
          for (const d in result) {
            setData.labels.push(d);
            setData.data.push(result[d]);
            setData.background.push(
              "#" + Math.floor(Math.random() * 16777215).toString(16)
            );
          }
        })
        .catch((err) => {
          err;
          loadingService.setloader(false);
        });
    },
  },
  async beforeMount() {
    this.$eventBus.$emit("callCreditMethod");
    if (
      this.$router.currentRoute &&
      this.$router.currentRoute.params &&
      this.$router.currentRoute.params.id !== ""
    ) {
      this.URL = "https://chart.datagma.com/api/data/file/".concat(
        this.$router.currentRoute.params.id
      );
      await this.callChartApI(
        "/output_person_location",
        this.output_person_location,
        this.URL
      );
      await this.callChartApI(
        "/output_person_extractedGender",
        this.output_person_extractedGender,
        this.URL
      );
      await this.callChartApI(
        "/output_person_extractedRole",
        this.output_person_extractedRole,
        this.URL
      );

      await this.callChartApI(
        "/output_person_extractedSeniority",
        this.output_person_extractedSeniority,
        this.URL
      );
      await this.callChartApI(
        "/output_company_french_codeNaf",
        this.output_company_french_codeNaf,
        this.URL
      );
      await this.callChartApI(
        "/output_company_french_libelleCodeNaf",
        this.output_company_french_libelleCodeNaf,
        this.URL
      );
      await this.callChartApI(
        "/output_company_french_effectif",
        this.output_company_french_effectif,
        this.URL
      );
      await this.callChartApI(
        "/output_company_full_cards_overview_fields_numEmployee",
        this.output_company_full_cards_overview_fields_numEmployee,
        this.URL
      );
      await this.callChartApI(
        "/output_company_premium_industries",
        this.output_company_premium_industries,
        this.URL
      );
      await this.callChartApI(
        "/output_company_premium_companySize",
        this.output_company_premium_companySize,
        this.URL
      );
      await this.callChartApI(
        "/output_company_premium_type",
        this.output_company_premium_type,
        this.URL
      );
    } else {
      throw "Id not found";
    }
  },
};
</script>
<style scoped>
.card-body h5 {
  text-align: center;
  padding: 10px;
}

.padding {
  padding: 20px 10px;
}
</style>
